import React, { useState, useEffect } from "react";
import '../css/Contact.css';
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        type: '',
        source: 'website',
        medium: 'none',
        campaign: 'none'
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        setFormData(prevState => ({
          ...prevState,
          source: urlParams.get('utm_source') || 'website',
          medium: urlParams.get('utm_medium') || 'none',
          campaign: urlParams.get('utm_campaign') || 'none',
          keyword: urlParams.get('keyword') || 'none'
        }));
        // console.log("user Searched for :-", keyword);
      }, []);
    
      const validateForm = () => {
        const newErrors = {};
    
        // Name validation (only letters and spaces)
        if (!formData.name.trim()) {
          newErrors.name = 'Name is required';
        } else if (!/^[A-Za-z\s]{3,}$/.test(formData.name.trim())) {
          newErrors.name = 'Name should contain only letters and be at least 3 characters long';
        }
    
        // Mobile validation
        if (!formData.mobile) {
          newErrors.mobile = 'Mobile number is required';
        } else if (!/^[6-9]\d{9}$/.test(formData.mobile)) {
          newErrors.mobile = 'Enter a valid 10-digit mobile number starting with 6, 7, 8, or 9';
        }
    
        // Email validation
        if (!formData.email) {
          newErrors.email = 'Email is required';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
          newErrors.email = 'Enter a valid email address';
        }
    
        // Property type validation
        if (!formData.type) {
          newErrors.type = 'Please select a property type';
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        
        // Special handling for mobile number
        if (name === 'mobile') {
          const mobileRegex = /^[0-9]*$/;
          if (value === '' || mobileRegex.test(value)) {
            if (value.length <= 10) {
              setFormData(prevState => ({
                ...prevState,
                [name]: value
              }));
            }
          }
          return;
        }
    
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
    
        // Clear error when user starts typing
        if (errors[name]) {
          setErrors(prev => ({
            ...prev,
            [name]: ''
          }));
        }
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) {
            return;
        }

        setIsSubmitting(true);
        
        try {
            // Create a new FormData object with Zoho's required fields
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = 'https://crm.zoho.in/crm/WebToLeadForm';
            form.acceptCharset = 'UTF-8';

            // Add hidden fields required by Zoho
            const hiddenFields = {
                'xnQsjsdp': '9ca91115db3fa5db905f0a5f9b52fa62140a4ffdfb99a2299f8843ad23ced3dd',
                'zc_gad': '',
                'xmIwtLD': '1bd667aa4e0c5fbe19b2063af3182da11951d68f6362aa8389f4c2e8e7a5362d641dfde87d60d54fd52a54edd91edf29',
                'actionType': 'TGVhZHM=',
                // 'returnURL': ''
            };


            const response = await fetch('https://script.google.com/macros/s/AKfycbxY70Be15h75y1XmFFSatm0zmn6zRL2BKmj9GNpTtM1eLkEGwJbWOKjiGAk7c87hZlF/exec', {
              method: 'POST',
              body: JSON.stringify(formData),
              headers: {
                'Content-Type': 'application/json',
              },
              mode: 'no-cors'
            });

            // Map your form data to Zoho fields
            const zohoFields = {
                'Last Name': formData.name,
                'Mobile': formData.mobile,
                'Email': formData.email,
                'LEADCF9': formData.type,  // Property Type
                'Lead Source': formData.source,
                'LEADCF10': formData.medium, // lead medium
                'LEADCF11': formData.campaign // lead campaign
            };

            // Combine all fields
            const allFields = { ...hiddenFields, ...zohoFields };

            // Create and append input elements
            Object.keys(allFields).forEach(key => {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = allFields[key];
                form.appendChild(input);
            });

            // Append form to body, submit it, and remove it
            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);

            // Set the form submission flag in localStorage
            localStorage.setItem('formSubmitted', 'true');
          

            // Clear form (except UTM parameters)
            const { source, medium, campaign } = formData;
            setFormData(prevState => ({
                ...prevState,
                name: '',
                mobile: '',
                email: '',
                type: '',
                source,
                medium,
                campaign
            }));
            
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('There was an error submitting the form. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="contact-section container">
            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <div className="contact-left">
                        <h2>Contact <span className="highlight">Us</span></h2>

                        <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="address-section">
                                <h3>Corporate Address</h3>
                                <p>26, Hitech City Road,</p>
                                <p>Jubilee Enclave,</p>
                                <p>Hyderabad, Telangana,</p>
                                <p>500084.</p>
                            
                                <div className="social-links">
                                <a href="https://www.instagram.com/samracana_projects/" className="social-icon"><FaInstagram /></a>
                                <a href="https://www.facebook.com/profile.php?id=61557051024369" className="social-icon"><FaFacebookF /></a>
                                <a href="https://www.youtube.com/@SamracanaProjectsLLP" className="social-icon"><FaYoutube /></a>
                                <a href="https://x.com/Samracana" className="social-icon"><FaTwitter /></a>
                            </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                        <div className="contact-details">
                            <div className="call-section">
                                <h3>Call Us</h3>
                                <p><a href="tel:9198498401113">+91 984 984 0111</a></p>
                            </div>

                            <div className="email-section">
                                <h3>Email Us</h3>
                                <p><a href="mailto:sales@samracana.com">sales@samracana.com</a></p>
                                <p><a href="mailto:info@samracana.com">info@samracana.com</a></p>
                            </div>
                        </div>

                        </div>

                        

                        </div>

                        
                    </div>
                </div>

                <div className="col-lg-2 col-md-2"></div>

                <div className="col-lg-4 col-md-4">
                    <div className="contact-form">
                        <h2>Get in touch with us</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Your Name*"
                                    className={`form-input ${errors.name ? 'error' : ''}`}
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.name && <span className="error-message">{errors.name}</span>}
                            </div>

                            <div className="input-group">
                                <input
                                    type="tel"
                                    name="mobile"
                                    placeholder="Mobile Number*"
                                    className={`form-input ${errors.mobile ? 'error' : ''}`}
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.mobile && <span className="error-message">{errors.mobile}</span>}
                            </div>

                            <div className="input-group">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Your e-mail*"
                                    className={`form-input ${errors.email ? 'error' : ''}`}
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.email && <span className="error-message">{errors.email}</span>}
                            </div>

                            <div className="input-group">
                                <select
                                    name="type"
                                    className={`form-input ${errors.type ? 'error' : ''}`}
                                    value={formData.type}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select Property Type</option>
                                    <option value="2BHK">2 BHK</option>
                                    <option value="3BHK">3 BHK</option>
                                </select>
                                {errors.type && <span className="error-message">{errors.type}</span>}
                            </div>

                            <button 
                                type="submit" 
                                className="submit-button-contact"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <p>2024 Samracana, All Rights Reserved</p>
                <p>Powered by Nuecode Business Solutions</p>
            </div>
        </div>
    );
}

export default Contact;