import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import About from "../components/About";
import Overview from "../components/overview";
import Gallery from "../components/Gallery";
import Price from "../components/Price";
import Video from "../components/Video";
import Location from "../components/Location";
import FloorPlan from "../components/FloorPlan";
import Contact from "../components/Contact";
import ChatAndPopup from "../components/ChatAndPopup";

import '../css/Home.css';

const Home = () => {
    return (
        <div className="page-wrapper">
            <div className="navbar-wrapper">
            <Navbar />
            </div>
            <main className="main-content">
                <section>
                    <HeroSection />
                </section>
                <section className="about-section-home" id="about">
                    <About />
                </section>

                <section className="overview-section-home" id="overview">
                    <Overview />
                </section>

                <section className="gallery-section-home" id="gallery">
                    <Gallery />
                </section>

                <section className="price-section-home" id="price">
                    <Price />
                </section>

                <section className="video-section-home" id="video">
                    <Video />
                </section>

                <section className="FloorPlan-section-home" id="floorplan">
                    <FloorPlan />
                </section>

                <section className="Location-section-home" id="location">
                    <Location />
                </section>

                <section className="contact-section-home container-fluid" id="contact">
                    <Contact />
                </section>
            </main>
            {/* <ChatAndPopup /> */}
        </div>
    )
}

export default Home;
