import React, { useState, useEffect } from "react";
import '../css/About.css';
import FormPopup from "./FormPopup";

const About = () => {
    const [isFormPopupOpen, setIsFormPopupOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentImageIndex(prev => (prev === 0 ? 1 : 0));
        }, 2000);

        return () => clearInterval(timer);
    }, []);

    const scrollToContact = () => {
        const contactSection = document.getElementById('contact');
        if (contactSection) {
            contactSection.scrollIntoView({ 
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    const mobileImages = [
        require("../assets/images/about/1-1.webp"),
        require("../assets/images/about/2-2.webp")
    ];

    return (
        <section id="about" className="about-section">
            <div className="container">
                <div className="row align-items-center">
                    {/* Left Content */}
                    <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
                        <div className="about-text">
                            <h2 className="luxury-title">Indulge in the Luxury Life</h2>
                            <p className="about-description">
                                Samracana Swasthi offers more than just a home
                                it's a gateway to exceptional living. With world class
                                amenities such as a luxurious clubhouse, sparkling
                                swimming pool, fully equipped gym, yoga, serene
                                walking trails, a children's play area, and many
                                more exciting amenities, every moment is designed
                                to inspire and rejuvenate.
                            </p>
                            
                        </div>
                    </div>

                    {/* Right Content - Modified for responsive design */}
                    <div className="col-lg-6 col-md-12">
                        {/* Desktop View */}
                        <div className="desktop-gallery row">
                            {/* First Column */}
                            <div className="col-6">
                            {/* Living Room */}
                            <div className="mb-3">
                                <img
                                src={require("../assets/images/about/1-1.webp")}
                                alt="Luxury Living Room"
                                className="img-fluid gallery-image"
                                style={{ width: "327px", height: "295px" }}
                                />
                            </div>
                            {/* Playground */}
                            <div className="mb-3 text-end">
                                <img
                                src={require("../assets/images/about/1-2.webp")}
                                alt="Playground"
                                className="img-fluid gallery-image"
                                style={{ width: "169px", height: "113px", right:"0" }}
                                />
                            </div>
                            </div>

                            {/* Second Column */}
                            <div className="col-4">
                            {/* Bedroom */}
                            <div className="mb-3">
                                <img
                                src={require("../assets/images/about/2-1.webp")}
                                alt="Bedroom"
                                className="img-fluid gallery-image"
                                style={{ width: "197px", height: "130px" }}
                                />
                            </div>
                            {/* Gym */}
                            <div>
                                <img
                                src={require("../assets/images/about/2-2.webp")}
                                alt="Gym"
                                className="img-fluid gallery-image"
                                style={{ width: "197px", height: "206px" }}
                                />
                            </div>
                            </div>

                            {/* Third Column */}
                            <div className="col-2">
                            {/* Garden */}
                            <div className="mb-3">
                                <img
                                src={require("../assets/images/about/3-1.webp")}
                                alt="Garden"
                                className="img-fluid gallery-image"
                                style={{ width: "127px", height: "176px" }}
                                />
                            </div>
                            {/* Pool */}
                            <div>
                                <img
                                src={require("../assets/images/about/3-2.webp")}
                                alt="Pool"
                                className="img-fluid gallery-image"
                                style={{ width: "127px", height: "242px" }}
                                />
                            </div>
                            </div>
                        </div>

                        {/* Mobile View Slideshow */}
                        <div className="mobile-gallery">
                            <img
                                src={mobileImages[currentImageIndex]}
                                alt={`Slide ${currentImageIndex + 1}`}
                                className="img-fluid mobile-gallery-image"
                            />
                        </div>
                    </div>

                </div>

                <div className='button-about'>
                    <button className='about-button-form' onClick={scrollToContact}>Book Your's Now</button>
                </div>
                {/* <FormPopup isOpen={isFormPopupOpen} onClose={() => setIsFormPopupOpen(false)} /> */}
            </div>
        </section>
    );
};

export default About;