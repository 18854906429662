import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/FormPopup.css';

const FormPopup = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    type: '',
    source: '',
    medium: '',
    campaign: ''
  });

  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setFormData(prevState => ({
      ...prevState,
      source: urlParams.get('utm_source') || 'website',
      medium: urlParams.get('utm_medium') || 'none',
      campaign: urlParams.get('utm_campaign') || 'none'
    }));
  }, []);

  const validateForm = () => {
    const newErrors = {};

    // Name validation (only letters and spaces)
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    } else if (!/^[A-Za-z\s]{3,}$/.test(formData.name.trim())) {
      newErrors.name = 'Name should contain only letters and be at least 3 characters long';
    }

    // Mobile validation
    if (!formData.mobile) {
      newErrors.mobile = 'Mobile number is required';
    } else if (!/^[6-9]\d{9}$/.test(formData.mobile)) {
      newErrors.mobile = 'Enter a valid 10-digit mobile number starting with 6, 7, 8, or 9';
    }

    // Email validation
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Enter a valid email address';
    }

    // Property type validation
    if (!formData.type) {
      newErrors.type = 'Please select a property type';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Special handling for mobile number
    if (name === 'mobile') {
      const mobileRegex = /^[0-9]*$/;
      if (value === '' || mobileRegex.test(value)) {
        if (value.length <= 10) {
          setFormData(prevState => ({
            ...prevState,
            [name]: value
          }));
        }
      }
      return;
    }

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
        return;
    }

    setIsSubmitting(true);
    
    try {
        // Create a new FormData object with Zoho's required fields
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = 'https://crm.zoho.in/crm/WebToLeadForm';
        form.acceptCharset = 'UTF-8';

        // Add hidden fields required by Zoho
        const hiddenFields = {
            'xnQsjsdp': '1b8bee56e0bf7a84b10560783fa35d2c1c5af5ae44e62d50ce54cda5e368407b',
            'zc_gad': '',
            'xmIwtLD': '0297b71a894c56c86abbb8fbdfb2a60f39b9fcaeb70ad9f2f75952de553e4731fa6e53d3125a9989ed4f6bc6525a8dce',
            'actionType': 'TGVhZHM=',
            // 'returnURL': 'https://swasthi.samracana.com/thankyou'
        };

        const response = await fetch('https://script.google.com/macros/s/AKfycbzdxbonAYNXYSSdpFascPCJIz0ZBt0S_slGhFubppH1Vx2VgNzmOcQ_GJpTfubCf9IQ/exec', {
          method: 'POST',
          body: JSON.stringify(formData),
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'no-cors'
        });

        // Map your form data to Zoho fields
        const zohoFields = {
            'Last Name': formData.name,
            'Mobile': formData.mobile,
            'Email': formData.email,
            'LEADCF9': formData.type,  // Property Type
            'Lead Source': formData.source,
            'LEADCF10': formData.medium, // lead medium
            'LEADCF11': formData.campaign // lead campaign
        };

        // Combine all fields
        const allFields = { ...hiddenFields, ...zohoFields };

        // Create and append input elements
        Object.keys(allFields).forEach(key => {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = allFields[key];
            form.appendChild(input);
        });

        // Append form to body, submit it, and remove it
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);

        // Clear form (except UTM parameters)
        const { source, medium, campaign } = formData;
        setFormData(prevState => ({
            ...prevState,
            name: '',
            mobile: '',
            email: '',
            type: '',
            source,
            medium,
            campaign
        }));

        // navigate('/thankyou');
        onClose();
        
    } catch (error) {
        console.error('Error submitting form:', error);
        alert('There was an error submitting the form. Please try again.');
    } finally {
        setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <span className="popup-close" onClick={onClose}>X</span>
        <h2 className="popup-title">Just One Step Away</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="text"
              name="name"
              placeholder="Your Name*"
              className={`form-input ${errors.name ? 'error' : ''}`}
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.name && <span className="error-message">{errors.name}</span>}
          </div>

          <div className="input-group">
            <input
              type="tel"
              name="mobile"
              placeholder="Mobile Number*"
              className={`form-input ${errors.mobile ? 'error' : ''}`}
              value={formData.mobile}
              onChange={handleChange}
              required
            />
            {errors.mobile && <span className="error-message">{errors.mobile}</span>}
          </div>

          <div className="input-group">
            <input
              type="email"
              name="email"
              placeholder="Your e-mail*"
              className={`form-input ${errors.email ? 'error' : ''}`}
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <span className="error-message">{errors.email}</span>}
          </div>

          <div className="input-group">
            <select
              name="type"
              className={`form-input ${errors.type ? 'error' : ''}`}
              value={formData.type}
              onChange={handleChange}
              required
            >
              <option value="">Select Property Type</option>
              <option value="2 BHK">2 BHK</option>
              <option value="3 BHK">3 BHK</option>
            </select>
            {errors.type && <span className="error-message">{errors.type}</span>}
          </div>

          <button 
            type="submit" 
            className="submit-button"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default FormPopup;
