import React, {useEffect} from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ThankYou = () => {

    useEffect(() => {
        // Create a script element
        const script = document.createElement('script');
        script.type = 'text/javascript';

        // Add the Google Ads event snippet content
        script.innerHTML = `
            gtag('event', 'conversion', {
                'send_to': 'AW-16749921517/M7NGCPD5lO0ZEO2J_rI-',
                'value': 1.0,
                'currency': 'INR'
            });
        `;

        // Append the script to the head of the document
        document.head.appendChild(script);

        // Cleanup: remove the script when the component unmounts
        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return (
        <Container>
            <Overlay>
                <ContentWrapper
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    <Checkmark>✓</Checkmark>
                    <Title>Thank You!</Title>
                    <Message>
                        We've received your submission successfully.
                        Our team will get back to you shortly.
                    </Message>
                    <Subtitle>
                        Meanwhile, feel free to explore more about SAMRACANA
                    </Subtitle>
                    <BackButton href="/">Back to Home</BackButton>
                </ContentWrapper>
            </Overlay>
        </Container>
    );
};

const Container = styled.div`
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${require('../assets/images/thankyou.webp')});
    
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
        background-image: url(${require('../assets/images/th.webp')})
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
`;

const Overlay = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0,2);
`;

const ContentWrapper = styled(motion.div)`
    text-align: center;
    padding: 3rem;
    background: rgba(255,255,255,0.3);
    border-radius: 20px;
  
    max-width: 600px;
    margin: 20px;
`;

const Checkmark = styled.div`
    font-size: 5rem;
    color:rgba(9, 18, 53, 1) ;
    margin-bottom: 1rem;
`;

const Title = styled.h1`
    color: rgba(9, 18, 53, 1);
    font-size: 2.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
`;

const Message = styled.p`
    color: black;
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
`;

const Subtitle = styled.p`
    color: black;
    font-size: 1rem;
    line-height: 1.4;
    margin-bottom: 2rem;
`;

const BackButton = styled.a`
    display: inline-block;
    padding: 12px 30px;
    background: rgba(9, 18, 53, 1);
    color: white;
    text-decoration: none;
    border-radius: 25px;
    font-weight: 500;
    transition: all 0.3s ease;

    &:hover {
        background:rgba(246, 214, 115, 1);
        transform: translateY(-2px);
        color: rgba(9, 18, 53, 1);
    }
`;

export default ThankYou;