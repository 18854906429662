import React from "react";
import '@justinribeiro/lite-youtube';
import '../css/Video.css';

const Video = () => {
    return (
        <div className="video-section">
            <div className="video-container">
                <div className='gallery-title'>
                    <h2>TAKE A TOUR</h2>
                    <img 
                        className='decorative-image' 
                        src={require('../assets/images/des-blue.webp')} 
                        alt='decorative'
                        width="389"
                        height="88"
                        loading="lazy"
                    />
                </div>
                <lite-youtube 
                    videoid="0Cych7rPbMs"
                    playlabel="Take a Tour"
                    params="rel=0"
                    title="YouTube video player"
                    style={{ background: 'transparent' }}
                    allowfullscreen
                    loading="lazy"
                />
            </div>
        </div>
    );
};

export default Video;
