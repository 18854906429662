import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import '../css/Navbar.css';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = () => {
        setIsOpen(false);
    }

    return (
        <nav className="navbar">
            <div className="navbar-brand">
                <img src={require('../assets/images/logo/Swasthi Logo.webp')} alt="Samracana Swasthi" className="logo" />
            </div>

            <div className={`nav-links ${isOpen ? 'active' : ''}`}>
                <a href='#' className='nav-item' onClick={handleLinkClick}>Home</a>
                <a href='#about' className='nav-item' onClick={handleLinkClick}>About</a>
                <a href='#gallery' className='nav-item' onClick={handleLinkClick}>Gallery</a>
                <a href='#floorplan' className='nav-item' onClick={handleLinkClick}>Floor Plan</a>
                <a href='#location' className='nav-item' onClick={handleLinkClick}>Location</a>
                <a href='#contact' className='nav-item' onClick={handleLinkClick}>Contact</a>
            </div>

            <div className="contact-info">
                <a href="tel:+919849840111" className="phone-link">
                    <img src={require('../assets/images/phone-call.png')} alt="call" className="call-icon" />
                    <span className="phone-number">+91 984 984 0111</span>
                </a>
            </div>

            <div className="mobile-actions">
                <a href="tel:+919849840111" className="mobile-phone-link">
                    <img src={require('../assets/images/phone-call.png')} alt="call" className="call-icon" />
                </a>
                <button className="hamburger" onClick={toggleMenu}>
                    <span className={`hamburger-line ${isOpen ? 'open' : ''}`}></span>
                </button>
            </div>
        </nav>
    );
};

export default Navbar;