import React, { useState, useEffect } from 'react';
import FormPopup from './FormPopup';
import '../css/Gallery.css';

const Gallery = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isFormPopupOpen, setIsFormPopupOpen] = useState(false);
    
    const imageData = [
        {
            src: require('../assets/images/gallery/1.webp'),
            description: 'Front View'
        },
        {
            src: require('../assets/images/gallery/2.webp'),
            description: 'Indoor Games'
        },
        {
            src: require('../assets/images/gallery/3.webp'),
            description: 'Party Hall'
        },
        {
            src: require('../assets/images/gallery/4.webp'),
            description: 'Theater'
        },
        {
            src: require('../assets/images/gallery/5.webp'),
            description: 'Children Play Area'
        },
        {
            src: require('../assets/images/gallery/6.webp'),
            description: 'Bed Room'
        },
        {
            src: require('../assets/images/gallery/7.webp'),
            description: 'Banquet Hall'
        },
        {
            src: require('../assets/images/gallery/8.webp'),
            description: 'Outdoor Gym'
        },
        {
            src: require('../assets/images/gallery/9.webp'),
            description: 'Club House'
        },
        {
            src: require('../assets/images/gallery/10.webp'),
            description: 'Aerial View'
        },

        // ... add descriptions for all images
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevSlide) => 
                prevSlide === imageData.length - 1 ? 0 : prevSlide + 1
            );
        }, 4000); 

        return () => clearInterval(timer);
    }, []);


    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imageData.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + imageData.length) % imageData.length);
    };

    const scrollToContact = () => {
        const contactSection = document.getElementById('contact');
        if (contactSection) {
            contactSection.scrollIntoView({ 
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    return (
        <div className='gallery-section'>
            <div className='gallery-container'>
                <div className='gallery-title'>
                    <h2>GALLERY</h2>
                    <img 
                        className='decorative-image' 
                        src={require('../assets/images/des-blue.webp')} 
                        alt='decorative'
                        width="389"
                        height="88"
                    />
                </div>
                <div className='image-description'>
                    <p>{imageData[currentIndex].description}</p>
                </div>
                <div className='carousel-container'>
                    <div className='side-image prev-image'>
                        <img 
                            src={imageData[(currentIndex - 1 + imageData.length) % imageData.length].src} 
                            alt="Previous" 
                        />
                    </div>
                    <div className='main-image'>
                        <img 
                            src={imageData[currentIndex].src} 
                            alt="Current" 
                        />
                    </div>
                    <div className='side-image next-image'>
                        <img 
                            src={imageData[(currentIndex + 1) % imageData.length].src} 
                            alt="Next" 
                        />
                    </div>
                </div>
                <div className='carousel-controls'>
                    <button className='control-button' onClick={prevSlide}>&#10094;</button>
                    <button className='control-button' onClick={nextSlide}>&#10095;</button>
                </div>
                <div className='button-gallery'>
                    <button className='gallery-button-form' onClick={scrollToContact}>Take the First Step</button>
                </div>
                {/* <FormPopup isOpen={isFormPopupOpen} onClose={() => setIsFormPopupOpen(false)} /> */}
            </div>
        </div>
    );
}

export default Gallery;