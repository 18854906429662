import React from "react";
import ZohoForm from "./ZohoForm";

const WebForm = () => {
    return (
        <>
        <ZohoForm />
        </>
    )
}

export default WebForm;