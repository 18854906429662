import React from "react";
import '../css/overview.css';

const Overview = () => {
    return (
        <section className="overview-section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="image-container">
                            <div className="yellow-frame">
                                
                                <img 
                                    src={require('../assets/images/Overview.webp')}
                                    alt="Samracana Swasthi Building"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7">
                        <div className="overview-content">
                            <h2 className="overview-title">OVERVIEW</h2>
                            <h3 className="overview-subtitle">AT KOLLUR, HYDERABAD</h3>
                            <p className="overview-description">
                                Our 2 & 3 BHK premium flats in Kollur, Hyderabad, 
                                are strategically positioned in the city's next growth corridor, offering seamless connectivity. 
                                The gated community ensures safety and tranquility, making it an ideal choice for those seeking 
                                modern living. The homes are built with cutting-edge technology for enhanced quality and living experience.
                            </p>
                        </div>
                    </div>
                </div>        
            </div>
            
        </section>
    );
};

export default Overview;
