import React, { useState } from "react";
import FormPopup from "./FormPopup";
import '../css/FloorPlan.css';

const FloorPlan = () => {
    // State to track which plan is selected (default to 2BHK)
    const [selectedPlan, setSelectedPlan] = useState('Tower A');
    const [isFormPopupOpen, setIsFormPopupOpen] = useState(false);


    // Function to handle button clicks
    const handlePlanSelect = (plan) => {
        setSelectedPlan(plan);
    };

    const scrollToContact = () => {
        const contactSection = document.getElementById('contact');
        if (contactSection) {
            contactSection.scrollIntoView({ 
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    return (
        <>
            <div className="floorplan-section">
                <div className="floorplan-container">
                    <div className="floorplan-title">
                        <h2>FLOOR PLANNING</h2>
                        <img src={require('../assets/des-blue2.png')} alt="Floor Plan" className="title-decoration-floorplan" />
                    </div>
                    <div className="floorplan-buttons">
                        <div className="button-group">
                            <button 
                                className={`tower-button ${selectedPlan === 'Tower A' ? 'active' : ''}`}
                                onClick={() => handlePlanSelect('Tower A')}
                            >
                                Tower A
                            </button>
                            <button 
                                className={`tower-button ${selectedPlan === 'Tower B' ? 'active' : ''}`}
                                onClick={() => handlePlanSelect('Tower B')}
                            >
                                Tower B
                            </button>
                            <button 
                                className={`tower-button ${selectedPlan === 'Tower C' ? 'active' : ''}`}
                                onClick={() => handlePlanSelect('Tower C')}
                            >
                                Tower C
                            </button>
                        </div>
                    </div>
                    <div className="floorplan-img-container">
                        {selectedPlan === 'Tower A' && (
                            <img 
                                src={require('../assets/images/floor/Tower A.webp')} 
                                alt="Tower A Floor Plan" 
                                className="floorplan-img"
                            />
                        )}
                        {selectedPlan === 'Tower B' && (
                            <img 
                                src={require('../assets/images/floor/Tower B.webp')} 
                                alt="Tower B Floor Plan" 
                                className="floorplan-img"
                            />
                        )}
                        {selectedPlan === 'Tower C' && (
                            <img 
                                src={require('../assets/images/floor/Tower C.webp')} 
                                alt="Tower C Floor Plan" 
                                className="floorplan-img"
                            />
                        )}
                    </div>
                    <div className="signup-button-container">
                    <button className="signup-button" onClick={scrollToContact}>Book a Visit</button>
                    </div>
                </div>
            </div>
            {/* <FormPopup isOpen={isFormPopupOpen} onClose={() => setIsFormPopupOpen(false)} /> */}
        </>
    )
}

export default FloorPlan;