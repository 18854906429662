import React from "react";
import '../css/Location.css';

const Location = () => {
    const nearbyPlaces = [
        { name: "The Gaudium School", time: "4 min" },
        { name: "Samashti Intl. School", time: "5 mins" },
        { name: "Kondakal SEZ", time: "5 mins" },
        { name: "Candidius Intl. School", time: "5 mins" },
        { name: "Kokapet SEZ", time: "15 mins" },
        { name: "Financial District", time: "20 mins" },
    ];

    const handleMapClick = () => {
        window.open('https://maps.app.goo.gl/1MnRkLjdkrLxuDtr7', '_blank');
    };

    return (
        <div className="location-section">
            <div className="location-container">
                <div className="location-title">
                    <h2>LOCATION</h2>
                    <img src={require('../assets/des-blue2.png')} alt="location" className="title-decoration-location"/>
                </div>
                
                <div className="location-content">
                    <p className="location-description">
                        Discover the charm of living in one of Hyderabad's most desirable neighborhoods with Serenity
                        Ventures. Offering 2 & 3 BHK luxury apartments near Kollur ORR, this premium gated community
                        combines comfort, elegance, and exceptional convenience.
                    </p>
                    
                    <div className="location-map-container">
                        <div className="map-section">
                            <img 
                                src={require('../assets/images/location.png')} 
                                alt="Location Map" 
                                className="location-map"
                                onClick={handleMapClick}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                        <div className="nearby-places">
                            {nearbyPlaces.map((place, index) => (
                                <div key={index} className="place-card">
                                    {place.name} <span>{place.time}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Location;

