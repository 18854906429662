import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import FormPopup from './FormPopup';
import StickyPopup from './stickyPopup';
import ChatAndPopup from './ChatAndPopup';
import '../css/HeroSection.css';

const HeroSection = () => {
    const [isFormPopupOpen, setIsFormPopupOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const openFormPopup = () => {
        setIsFormPopupOpen(true);
    };

    const closeFormPopup = () => {
        setIsFormPopupOpen(false);
    };

    const scrollToContact = () => {
        const contactSection = document.getElementById('contact');
        if (contactSection) {
            contactSection.scrollIntoView({ 
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        lazyLoad: 'ondemand',
    };

    return (
        <div className="hero-container">
            <link
                rel="preload"
                as="image"
                className='slide'
                href={require(`../assets/images/slider/${isMobile ? 'MB LP Hero - 1' : '1.1.2'}.webp`)}
            />
            <Slider {...settings}>
               
                <div className="slide">
                    <LazyLoadImage 
                     
                        src={require(`../assets/images/slider/${isMobile ? 'MB LP Hero - 1.1' : '1.1.2'}.webp`)} 
                        alt="Slide 1"
                         
                    />
                    <div className="slide-content left-top">
                        <h2>Rising Above, Redefining Luxury Living</h2>
                        <button className="know-more-btn" onClick={scrollToContact}>Know More</button>
                    </div>
                </div>
                <div className="slide">
                    < LazyLoadImage
                        src={require(`../assets/images/slider/${isMobile ? 'MB LP Hero - 2.1' : '2.2.1'}.webp`)} 
                        alt="Slide 2" 
                    />
                    <div className="slide-content right-top">
                        <h2>Experience the Peak of Comfort and Style</h2>
                        <button className="know-more-btn" onClick={scrollToContact}>Know More</button>
                    </div>
                </div>
                <div className="slide">
                    <LazyLoadImage 
                        src={require(`../assets/images/slider/${isMobile ? 'MB LP Hero - 3.1' : '3.4'}.webp`)} 
                        alt="Slide 3" 
                    />
                    <div className="slide-content left-center">
                        <h2 className='slideThree'>Where Relaxation and Recreation Meet Together</h2>
                        <button className="know-more-btn" onClick={scrollToContact}>Know More</button>
                    </div>
                </div>
                
            </Slider>
            <button className="sticky-button" onClick={scrollToContact}>Book a Visit</button>
            {/* <FormPopup isOpen={isFormPopupOpen} onClose={closeFormPopup} /> */}
            <StickyPopup />
            {/* <ChatAndPopup /> */}
        </div>
    );
};

export default HeroSection;
