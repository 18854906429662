import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import ThankYou from './pages/ThankYou';
import 'bootstrap/dist/css/bootstrap.min.css';
import WebForm from './components/WebForms';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/thankyou' element={<ThankYou />} />
      <Route path='/webform' element={<WebForm />} />
     </Routes>
    </BrowserRouter>
  );
}

export default App;
