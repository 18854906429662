import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/stickyPopup.css';

const StickyPopup = () => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [isVisible, setIsVisible] = useState(true);
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        type: '',
        source: '',
        medium: '',
        campaign: ''
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            const contactSection = document.querySelector(".contact-section-home");
            if (contactSection) {
                const rect = contactSection.getBoundingClientRect();
                const isContactVisible = rect.top < window.innerHeight && rect.bottom > 0;
                setIsVisible(!isContactVisible);
            }
        };

        window.addEventListener('scroll', handleScroll);
        // Initial check
        handleScroll();

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        setFormData(prevState => ({
            ...prevState,
            source: urlParams.get('utm_source') || 'website',
            medium: urlParams.get('utm_medium') || 'none',
            campaign: urlParams.get('utm_campaign') || 'none',
            keyword: urlParams.get('keyword') || 'none'
        }));
    }, []);

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
        } else if (!/^[A-Za-z\s]{3,}$/.test(formData.name.trim())) {
            newErrors.name = 'Name should contain only letters and be at least 3 characters long';
        }

        if (!formData.mobile) {
            newErrors.mobile = 'Mobile number is required';
        } else if (!/^[6-9]\d{9}$/.test(formData.mobile)) {
            newErrors.mobile = 'Enter a valid 10-digit mobile number starting with 6, 7, 8, or 9';
        }

        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Enter a valid email address';
        }

        if (!formData.type) {
            newErrors.type = 'Please select a property type';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'mobile') {
            const mobileRegex = /^[0-9]*$/;
            if (value === '' || mobileRegex.test(value)) {
                if (value.length <= 10) {
                    setFormData(prevState => ({
                        ...prevState,
                        [name]: value
                    }));
                }
            }
            return;
        }

        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) {
            return;
        }

        setIsSubmitting(true);
        
        try {
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = 'https://crm.zoho.in/crm/WebToLeadForm';
            form.acceptCharset = 'UTF-8';

            const hiddenFields = {
                'xnQsjsdp': '9ca91115db3fa5db905f0a5f9b52fa62140a4ffdfb99a2299f8843ad23ced3dd',
                'zc_gad': '',
                'xmIwtLD': '1bd667aa4e0c5fbe19b2063af3182da11951d68f6362aa8389f4c2e8e7a5362d641dfde87d60d54fd52a54edd91edf29',
                'actionType': 'TGVhZHM='
            };

            await fetch('https://script.google.com/macros/s/AKfycbxY70Be15h75y1XmFFSatm0zmn6zRL2BKmj9GNpTtM1eLkEGwJbWOKjiGAk7c87hZlF/exec', {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json',
                },
                mode: 'no-cors'
            });

            const zohoFields = {
                'Last Name': formData.name,
                'Mobile': formData.mobile,
                'Email': formData.email,
                'LEADCF9': formData.type,
                'Lead Source': formData.source,
                'LEADCF10': formData.medium,
                'LEADCF11': formData.campaign
            };

            const allFields = { ...hiddenFields, ...zohoFields };

            Object.keys(allFields).forEach(key => {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = allFields[key];
                form.appendChild(input);
            });

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);

            const { source, medium, campaign } = formData;
            setFormData(prevState => ({
                ...prevState,
                name: '',
                mobile: '',
                email: '',
                type: '',
                source,
                medium,
                campaign
            }));

            // navigate('/thankyou');
            setIsExpanded(false);
            
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('There was an error submitting the form. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className="sticky-popup">
            {!isExpanded && (
                <div className="popup-minimized">
                    <span>Speak to Our Agent</span>
                    <button className="expand-btn" onClick={() => setIsExpanded(true)}>+</button>
                </div>
            )}

            {isExpanded && (
                <div className="popup-expanded">
                    <div className="popup-header">
                        <span className='popup-header-title'>Speak to Our Agent</span>
                        <button className="close-btn" onClick={() => setIsExpanded(false)}>×</button>
                    </div>
                    <div className="popup-content-sticky">
                        <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Your Name*"
                                    className={errors.name ? 'error' : ''}
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.name && <span className="error-message">{errors.name}</span>}
                            </div>

                            <div className="input-group">
                                <input
                                    type="tel"
                                    name="mobile"
                                    placeholder="Mobile Number*"
                                    className={errors.mobile ? 'error' : ''}
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.mobile && <span className="error-message">{errors.mobile}</span>}
                            </div>

                            <div className="input-group">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Your e-mail*"
                                    className={errors.email ? 'error' : ''}
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.email && <span className="error-message">{errors.email}</span>}
                            </div>

                            <div className="input-group">
                                <select
                                    name="type"
                                    className={errors.type ? 'error' : ''}
                                    value={formData.type}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select Property Type</option>
                                    <option value="2 BHK">2 BHK</option>
                                    <option value="3 BHK">3 BHK</option>
                                </select>
                                {errors.type && <span className="error-message">{errors.type}</span>}
                            </div>

                            <button 
                                type="submit" 
                                className="submit-button-sticky"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StickyPopup; 