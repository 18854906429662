import React, { useState, useEffect } from "react";
import FormPopup from "./FormPopup";
import '../css/Price.css';

const Price = () => {
    const [isFormPopupOpen, setIsFormPopupOpen] = useState(false);
    const [showPrices, setShowPrices] = useState(false);

    useEffect(() => {
        // Check if user has submitted the form
        const hasSubmittedForm = localStorage.getItem('formSubmitted') === 'true';
        setShowPrices(hasSubmittedForm);
    }, []);

    const scrollToContact = () => {
        const contactSection = document.getElementById('contact');
        if (contactSection) {
            contactSection.scrollIntoView({ 
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    return (
        <div className="price-container">
            {/* Highlights Section */}
            <div className="highlights">
                <div className="highlight-item">
                    <img src={require('../assets/images/price-icons/1.webp')} alt="Blocks" />
                    <div>
                        <span className="highlight-number">3</span>
                        <span className="highlight-text">Towers</span>
                    </div>
                </div>
                <div className="highlight-item">
                    <img src={require('../assets/images/price-icons/2.webp')} alt="Floors" />
                    <div>
                        <span className="highlight-number">17</span>
                        <span className="highlight-text">Floors</span>
                    </div>
                </div>
                <div className="highlight-item">
                    <img src={require('../assets/images/price-icons/3.webp')} alt="Units" />
                    <div>
                        <span className="highlight-number">323</span>
                        <span className="highlight-text">Units</span>
                    </div>
                </div>
                <div className="highlight-item">
                    <img src={require('../assets/images/price-icons/4.webp')} alt="Clubhouse" />
                    <div>
                        <span className="highlight-number">18,000 sft.</span>
                        <span className="highlight-text"> Clubhouse</span>
                    </div>
                </div>
            </div>

            {/* Price Section */}
            <div className="price-section">
                <div className="price-title">
                    <h2>PRICE</h2>
                    <img src={require('../assets/images/des-yel.png')} alt="decorative" className="title-decoration img-fluid" />
                </div>
                <div className="price-table">
                    <div className="table-header">
                        <span>Type</span>
                        <span>Area</span>
                        <span>Price</span>
                    </div>
                    <div className="table-row">
                        <span>2 BHK</span>
                        <span>1250 - 1270 Sft.</span>
                        {showPrices ? (
                            <span>₹ 74.98 L*</span>
                        ) : (
                            <button className="unlock-btn" onClick={scrollToContact}>Unlock Price</button>
                        )}
                    </div>
                    <div className="table-row">
                        <span>3 BHK</span>
                        <span>1850 - 2000 Sft.</span>
                        {showPrices ? (
                            <span>₹ 1.1 Cr*</span>
                        ) : (
                            <button className="unlock-btn" onClick={scrollToContact}>Unlock Price</button>
                        )}
                    </div>
                </div>
            </div>
            {/* <FormPopup isOpen={isFormPopupOpen} onClose={() => setIsFormPopupOpen(false)} /> */}
        </div>
    );
}

export default Price;