import React, { useEffect } from "react";

const ZohoForm = () => {
  useEffect(() => {
    // Define the function globally
    window.checkMandatory806794000000362005 = () => {
      const fields = ["Last_Name"];
      for (let i = 0; i < fields.length; i++) {
        const field = document.forms["WebToLeads806794000000362005"][fields[i]];
        if (field && field.value.trim() === "") {
          alert(fields[i] + " cannot be empty.");
          field.focus();
          return false;
        }
      }
      return true;
    };

    // Load the external Zoho Analytics Tracking script dynamically
    const script = document.createElement("script");
    script.src =
      "https://crm.zohopublic.in/crm/WebFormAnalyticsServeServlet?rid=48852a2b07e8c6afe96ca878de0d4a6905fef202c9bacfd081dd0130af8baff04a16766ba9fb5dd9de2594e83bef8bf0gid7d48ec4625ec5546c4601467279f88ae1f4586d41502e27b45dc7622079340adgid1d74bbe8b43a01f3aa1eb5b6a9e96278d9d9219bc1ad4fc0eddf794b5b11058bgidc17706f283adff0f7364fcb23f8aac55e7639e591a79fa7f2830ae6620318bdc&tw=1b91c0b6d68b898218c19ac3e6670959969a4b60880f62bb0ab6aa42dedd20e1";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script on component unmount
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      id="crmWebToEntityForm"
      className="zcwf_lblLeft crmWebToEntityForm"
      style={{ backgroundColor: "white", color: "black", maxWidth: "600px" }}
      dangerouslySetInnerHTML={{
        __html: `
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta http-equiv="content-type" content="text/html;charset=UTF-8">
        <form
          id="webform806794000000362005"
          action="https://crm.zoho.in/crm/WebToLeadForm"
          name="WebToLeads806794000000362005"
          method="POST"
          onSubmit="javascript:document.charset='UTF-8'; return checkMandatory806794000000362005()"
          accept-charset="UTF-8"
        >
          <input type="hidden" name="xnQsjsdp" value="436e53b91d75cc046a1ba19b5b06b8350bd1d7f8021fb5e20878841789e29c51" />
          <input type="hidden" name="zc_gad" id="zc_gad" value="" />
          <input type="hidden" name="xmIwtLD" value="f427296464985532f697cfc9564f1de8ff6c787d36653dcae35d3477c75385d189254290351a46caaf46ada43d130c30" />
          <input type="hidden" name="actionType" value="TGVhZHM=" />
          <input type="hidden" name="returnURL" value="http://localhost:3000" />
          <div class="zcwf_title" style="color: black; font-family: Arial;">Test Sam</div>
          <div class="zcwf_row">
            <div class="zcwf_col_lab">
              <label for="Last_Name">Last Name<span style="color:red;">*</span></label>
            </div>
            <div class="zcwf_col_fld">
              <input type="text" id="Last_Name" name="Last Name" maxlength="80" required />
            </div>
          </div>
          <div class="zcwf_row">
            <div class="zcwf_col_lab">
              <label for="Email">Email</label>
            </div>
            <div class="zcwf_col_fld">
              <input type="email" id="Email" name="Email" maxlength="100" />
            </div>
          </div>
          <div class="zcwf_row">
            <div class="zcwf_col_lab">
              <label for="Phone">Phone</label>
            </div>
            <div class="zcwf_col_fld">
              <input type="text" id="Phone" name="Phone" maxlength="30" />
            </div>
          </div>
          <div class="zcwf_row">
            <div class="zcwf_col_lab">
              <label for="LEADCF4">Property Type</label>
            </div>
            <div class="zcwf_col_fld">
              <select id="LEADCF4" name="LEADCF4">
                <option value="-None-">-None-</option>
                <option value="2 BHK">2 BHK</option>
                <option value="3 BHK">3 BHK</option>
              </select>
            </div>
          </div>
          <div class="zcwf_row">
            <div class="zcwf_col_fld">
              <input type="submit" class="formsubmit zcwf_button" value="Submit" />
              <input type="reset" class="zcwf_button" value="Reset" />
            </div>
          </div>
        </form>
      `,
      }}
    />
  );
};

export default ZohoForm;
